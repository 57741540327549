import React from 'react';

const VideoItem = (props) => {
  return (
    <div className="w-full h-full">
      {props.title}
      <video
        width={1300}
        height={295}
        className="w-full h-full md:h-[295px]"
        playsInline
        controls
      >
        <source src={props.link} type="video/mp4" />
        Your browser does not support the video tag or this video format.
      </video>
    </div>
  );
};
export default VideoItem;
