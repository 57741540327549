import React, { useRef, useState } from "react";
import { CustomInput, CustomTextArea } from "../CustomInput/CustomInput";
import { Button } from "../Button/Button";
import bg from "../../assets/images/form-bg.png";
import truck from "../../assets/images/form-truck.png";
import { useIsVisible } from "../../hooks/useIsVisible";
import { AnimatePresence, motion } from "framer-motion";
import { alertToast } from "../../features/common/constants";

export const Form = () => {
  const [data, setData] = useState({
    name: "",
    phone_number: "",
    email: "",
    company_name: "",
    message: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    fetch("https://dev-app.easyfleet.ai/api/v1/common/consultation/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to send the quote");
        }
        return response.json();
      })
      .then(() => {
        alertToast("The quote successfully sent", "success");
        // Reset form data
        setData({
          name: "",
          phone_number: "",
          email: "",
          company_name: "",
          message: "",
        });
      })
      .catch((err) => {
        alertToast(err.message, "error");
      });
  };

  const truckAnimation = {
    initial: { x: "-1000px", scale: 0.9, opacity: 0.5 },
    animate: { x: "-500px", scale: 1, opacity: 1 },
    exit: { x: "-1000px", scale: 0.9, opacity: 0.5 },
    transition: { duration: 1, ease: "easeOut" },
  };
  const truckAnimationMobile = {
    initial: { x: "-800px", scale: 0.9, opacity: 0.5 },
    animate: { x: "-300px", scale: 1, opacity: 1 },
    exit: { x: "-800px", scale: 0.9, opacity: 0.5 },
    transition: { duration: 1, ease: "easeOut" },
  };

  const blockRef = useRef(null);

  const [isVisible] = useIsVisible(blockRef);

  return (
    <section className="mobile-container">
      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
        className="max-w-[1240px] m-auto rounded-md w-full mt-[200px] py-[90px] mt-[10px] md:mt-[90px]"
      >
        <AnimatePresence>
          {isVisible && (
            <motion.div
              {...truckAnimationMobile}
              className="w-1/2 relative mobile-only"
            >
              <img
                src={truck}
                alt="truck"
                className="min-h-[205px] min-w-[650px] absolute top-[100px] -translate-y-1/2"
              />
            </motion.div>
          )}
        </AnimatePresence>
        <h2 className="text-center text-black mt-[250px] md:mt-0 text-[30px] md:text-[46px] mb-[40px]">
          Send us a message to get consultation
        </h2>
        <div ref={blockRef} className="flex items-center overflow-hidden">
          {/* truck */}
          <AnimatePresence>
            {isVisible && (
              <motion.div
                {...truckAnimation}
                className="w-1/2 relative desktop-only"
              >
                <img
                  src={truck}
                  alt="truck"
                  className="min-h-[355px] min-w-[1100px] absolute top-1/2 -translate-y-1/2"
                />
              </motion.div>
            )}
          </AnimatePresence>
          <form
            onSubmit={handleSubmit}
            className="w-full md:w-1/2 ml-auto lg:pr-[50px] md:pr-[10pz] pl-2 pr-2"
          >
            <CustomInput
              placeholder="Your name"
              required
              name="name"
              value={data.name}
              onChange={handleInputChange}
              className="w-full mb-4"
            />
            <CustomInput
              placeholder="Phone number"
              required
              name="phone_number"
              value={data.phone_number}
              onChange={handleInputChange}
              className="w-full mb-4"
            />
            <CustomInput
              placeholder="Email address"
              required
              name="email"
              type="email"
              value={data.email}
              onChange={handleInputChange}
              className="w-full mb-4"
            />
            <CustomInput
              placeholder="Company name"
              required
              name="company_name"
              value={data.company_name}
              onChange={handleInputChange}
              className="w-full mb-4"
            />
            <CustomTextArea
              placeholder="Enter any details..."
              name="message"
              value={data.message}
              onChange={handleInputChange}
              className="w-full mb-4"
            />

            <div className="flex justify-end w-full">
              <Button className="mt-[30px]">Send your message</Button>
            </div>
          </form>
          <div id="form" />
        </div>
      </div>
    </section>
  );
};
