import React, { useEffect, useRef } from "react";
import leftLines from "../../assets/images/left-lines.png";
import rightLines from "../../assets/images/right-lines.png";
import { FeatureCard } from "../FeatureCard/FeatureCard";
import leftTruck from "../../assets/images/moving-truck-1.png";
import midTruck from "../../assets/images/moving-truck-2.png";
import rightTruck from "../../assets/images/moving-truck-3.png";
import { useIsVisible } from "../../hooks/useIsVisible";
import { useMediaQuery } from "../../hooks/useMediaQuery";

export const Features = () => {
  const blockRef = useRef(null);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [isVisible] = useIsVisible(blockRef, true);
  const SCROLL_STEP = isMobile ? 1 : 3;

  useEffect(() => {
    const trucks = document.querySelectorAll(".moving_truck");
    const block = blockRef.current;

    if (!isVisible || !block) return;

    let lastScrollTop = window.pageYOffset;
    let moveScrolls = [0, 0, 0];

    const handleScroll = () => {
      const scrollTopPosition = window.pageYOffset;
      const blockRect = block.getBoundingClientRect();

      const isSectionVisible =
        blockRect.top < window.innerHeight && blockRect.bottom > 0;

      if (!isSectionVisible) return;

      trucks.forEach((item, idx) => {
        let scrollStep = SCROLL_STEP;
        const moveScroll = moveScrolls[idx];

        if (scrollTopPosition > lastScrollTop) {
          item.style.transform = `translateY(${
            moveScroll + scrollStep + 1
          }px) translateX(-50%)`;
          moveScrolls[idx] += scrollStep;
        }
        if (scrollTopPosition < lastScrollTop) {
          item.style.transform = `translateY(${
            moveScroll - scrollStep
          }px) translateX(-50%)`;
          moveScrolls[idx] -= scrollStep;
        }
      });

      lastScrollTop = scrollTopPosition;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isVisible, SCROLL_STEP]);

  return (
    <div className="relative mobile-container" id="about">
      <img
        className="absolute left-0 top-[-200px] z-[-1]"
        src={leftLines}
        alt="lines"
      />
      <img
        className="absolute right-0 top-[-200px] z-[-1]"
        src={rightLines}
        alt="lines"
      />

      <div className="text-center flex flex-col justify-center items-center mt-[100px]">
        <h2 className="text-[50px] font-bold md:text-[50px] text-white max-w-[1040px]">
          Powerful Analytics at Your Fingertips
        </h2>
        <p className="text-[26px] md:text-[26px] text-[#FFF] max-w-[958px] mt-5">
          Unlock real-time insights on fleet performance with our easy-to-use
          platform. Gain actionable data on:
        </p>
      </div>
      <div
        ref={blockRef}
        className="max-w-[1240px] mx-auto mt-[77px] pr-2 pl-2"
      >
        <div className="flex flex-col md:flex-row justify-between overflow-hidden sm:pr-5 sm:pl-5">
          <div className="flex md:mb-0 mb-[40px] items-center flex-col gap-y-[40px]">
            <FeatureCard
              title={"Collaborate Effortlessly"}
              description={
                "Seamless teamwork with customizable user permissions and role-based access for unlimited users. Keep your fleet operations in sync, no matter where your team is located."
              }
              idx={0}
            />
            <FeatureCard
              title="Cut Maintenance Costs by "
              suffix={
                <span className="text-[#3E6DE5] font-semibold text-[35px]">
                  15%!
                </span>
              }
              description={
                "Say goodbye to unexpected repairs and expensive breakdowns. By automating maintenance schedules and tracking vehicle health, EasyFleet helps reduce maintenance costs by up to 15%."
              }
              idx={1}
            />
          </div>
          <div className="flex -z-10 flex-row justify-center md:justify-start gap-x-[100px] md:gap-x-[63px]">
            <div
              className="relative w-[20px] md:w-[31px] h-[400px] md:h-full"
              style={{
                background:
                  "linear-gradient(180deg, rgba(0, 125, 242, 0) 0%, #007DF2 43.75%, rgba(0, 125, 242, 0) 100%)",
              }}
            >
              <img
                className="moving_truck duration-75 absolute left-1/2 -translate-x-1/2 min-w-[50px] md:min-w-[60px]"
                src={leftTruck}
                alt="truck"
              />
            </div>
            <div
              className="relative w-[20px] md:w-[31px] h-[400px] md:h-full"
              style={{
                background:
                  "linear-gradient(180deg, rgba(0, 125, 242, 0) 0%, #007DF2 43.75%, rgba(0, 125, 242, 0) 100%)",
              }}
            >
              <img
                className="moving_truck duration-75 absolute left-1/2 -translate-x-1/2 min-w-[75px] md:min-w-[100px]"
                src={midTruck}
                alt="truck"
              />
            </div>
            <div
              className="relative w-[20px] md:w-[31px] h-[400px] md:h-full"
              style={{
                background:
                  "linear-gradient(180deg, rgba(0, 125, 242, 0) 0%, #007DF2 43.75%, rgba(0, 125, 242, 0) 100%)",
              }}
            >
              <img
                className="moving_truck duration-75 absolute left-1/2 -translate-x-1/2 min-w-[50px] md:min-w-[60px]"
                src={rightTruck}
                alt="truck"
              />
            </div>
          </div>
          <div className="flex flex-col items-center md:mt-0 mt-[40px] gap-y-[40px]">
            <FeatureCard
              title={"Boost Efficiency by "}
              suffix={
                <span className="text-[#3E6DE5] font-semibold text-[35px]">
                  25%!
                </span>
              }
              description={
                "Fleet managers using our platform see a 25% increase in overall efficiency. No more juggling spreadsheets or outdated software—EasyFleet simplifies everything for you."
              }
              idx={2}
            />
            <FeatureCard
              className="py-[12px]"
              title={"Transform Your Maintenance Process"}
              description={`Never miss a service again. EasyFleet automates service reminders, parts tracking, and work orders—so you can focus on growing your business while we keep your fleet running smoothly.`}
              idx={3}
              classNameTitle="!text-center"
              classNameDescription="md:!max-w-[329px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
