import React from "react";
import demoBg from "../../assets/images/demo-bg.jpg";
import sliderTruckWhite from "../../assets/images/white_truck.png";
import sliderTruckRed from "../../assets/images/red_truck.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel, Navigation } from "swiper/modules";
import "swiper/css";
import "./truck.css";
import { useMediaQuery } from "../../hooks/useMediaQuery";

export const Trucks = () => {
  const slides = [
    {
      image: sliderTruckWhite,
    },

    {
      image: sliderTruckRed,
    },
    {
      image: sliderTruckWhite,
    },

    {
      image: sliderTruckRed,
    },
    {
      image: sliderTruckWhite,
    },
    {
      image: sliderTruckRed,
    },
  ];
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <div
      className="relative mobile-container flex flex-col items-center !mt-[100px] !py-[64px] min-h-full md:min-h-[80dvh]"
      style={{
        backgroundImage: `url(${demoBg})`,

        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <h2 className="text-[30px] md:text-[60px] font-bold text-center max-w-[307px] md:max-w-[740px] mb-[20px]">
        Manage From Anywhere, No Limits!
      </h2>
      <p className="max-w-[740px] text-[16px] md:text-[26px] text-[#00000099]">
        From Semis to Pickups, We've Got You Covered!
      </p>

      <Swiper
        className="mt-[25px] slider max-w-full md:max-w-[1300px] lg:max-w-[1024px] trucks"
        mousewheel
        loop
        modules={[Autoplay, Navigation, Mousewheel]}
        spaceBetween={isMobile ? 40 : 66}
        slidesPerView={isMobile ? 1.3 : 3}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
          reverseDirection: false,
        }}
        speed={3000}
        initialSlide={Math.floor(2)}
        centeredSlides
        navigation
      >
        {slides.map((slide, idx) => (
          <SwiperSlide className="cursor-grab" key={idx}>
            <div className="flex flex-col items-center justify-center w-full h-full">
              <img src={slide.image} alt="logo" className="w-full h-full" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
