import { toast } from "react-toastify";

export const DEFAULT_CHART_OPTIONS = {
    responsive: true,
    plugins: {
        legend: {
        display: false
        }
    },
    scales: {
        x: {
            ticks: {
                display: false,
            },
        },
        y: {
            ticks: {
                display: false,
            },
        },
    },
};

export const alertToast = (message, type = 'success', options =   {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "light",
}) => {
    const notify = () => toast[ type ](message, options);
    notify();
}