import React from "react";
import logo from "../../assets/icons/logo.svg";
import telegram from "../../assets/icons/telegram.svg";
import instagram from "../../assets/icons/instagram.svg";
import linkedin from "../../assets/icons/linkedin.svg";

export const Footer = () => {
  return (
    <footer class="bg-[#26273C] dark:bg-gray-900 mt-[120px]">
      <div class="mx-auto w-full max-w-[1240px] p-4 py-6 lg:py-8">
        <a href="/" class="flex mobile-only items-center">
          <img
            src={logo}
            alt="Easy fleet Logo"
            className="w-[300px] h-[80px]"
          />
        </a>
        <div class="flex justify-between flex-col md:flex-row w-full items-center">
          <div class="mb-6 md:mb-0">
            <a href="/" class="flex desktop-only items-center">
              <img
                src={logo}
                alt="Easy fleet Logo"
                className="w-[300px] h-[80px]"
              />
            </a>
          </div>
          <div class="flex gap-x-32">
            {/* <div>
                        <h2 class="mb-6 text-sm font-semibold uppercase text-[#007DF2] text-[22px]">Product</h2>
                        <ul class="text-white font-medium flex flex-col gap-y-2">
                            <li>
                                <a href="/pricing" class="hover:underline">Home</a>
                            </li>
                            <li>
                                <a href="/pricing" class="hover:underline">Pricing</a>
                            </li>
                            <li>
                                <a href="/features" class="hover:underline">Features</a>
                            </li>
                        </ul>
                    </div> */}
            <div>
              <h2 class="mb-6 text-sm font-semibold uppercase text-[#007DF2] text-[22px]">
                Company
              </h2>
              <ul class="text-white font-medium flex flex-col gap-y-2">
                <li>
                  <a href="#about" class="hover:underline ">
                    About
                  </a>
                </li>

                <li>
                  <a href="#benefits" class="hover:underline">
                    Benefits
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 class="mb-6 text-sm font-semibold uppercase text-[#007DF2] text-[22px]">
                Contacts
              </h2>
              <ul class="text-white font-medium flex flex-col gap-y-2">
                <li>
                  <a
                    href="mailto:Contact@easyfleet.ai"
                    class="underline md:no-underline hover:no-underline md:hover:underline"
                  >
                    contact@easyfleet.ai
                  </a>
                </li>
                <li className="flex gap-x-1 items-center">
                  <p>Follow us: </p>
                  <ul className="flex gap-x-1">
                    <li>
                      <a
                        target="_blank"
                        className="flex items-center justify-center w-[20px] h-[20px] bg-[#3E6DE5] rounded-sm"
                        href="https://www.instagram.com/easyfleet.inctech?igsh=MWNuMzZ0MjBoaTkxeA=="
                        rel="noreferrer"
                      >
                        <img src={instagram} alt="instagram" />
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div class="text-center">
          <span class="text-sm text-gray-500 text-center dark:text-gray-400">
            Copyright © {new Date().getFullYear()} Easy Fleet - All Rights
            Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};
