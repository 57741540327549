import React from "react";
import laptop from "../../assets/images/laptop.png";
import phone from "../../assets/images/phone.png";
import demoBg from "../../assets/images/demo-bg.jpg";

export const Demo = () => {
  return (
    <div
      className="relative mobile-container pt-8 pb-2 flex flex-col justify-center items-center min-h-[60vh] md:min-h-screen"
      style={{
        backgroundImage: `url(${demoBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <div>
        <div className="m-auto max-w-[1100px] pt-[100px] text-center mb-20 md:mb-10 flex flex-col items-center">
          <h2 className="text-[25px] md:text-[50px] text-black font-bold">
            Automate Your Fleet Maintenance and Save up to 30% on Costs
          </h2>
          <p className="text-[14px] mt-[20px] text-[#131313] opacity-[0.6] md:text-[26px] ">
            Managing a fleet shouldn’t be a hassle. With EasyFleet, many clients
            have reported up to a 30% reduction in maintenance costs and a 40%
            boost in productivity. Our platform provides the tools and insights
            needed to streamline operations and reduce downtime.
          </p>
        </div>
        <div className="flex items-center justify-center h-full gap-y-5">
          <div>
            <img src={laptop} alt="laptop" />
          </div>
          <div>
            <img src={phone} alt="phone" />
          </div>
        </div>
      </div>
    </div>
  );
};
