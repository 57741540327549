import { useEffect, useState } from 'react';


export const useIsVisible = (ref, once, threshold = .5) => {
  const [ isIntersecting, setIntersecting ] = useState(false);

  useEffect(() => {
    let observed = false;
    
    const observer = new IntersectionObserver(([ entry ]) => {
      const isVisible = entry.isIntersecting
      if (observed && once) return
      setIntersecting(isVisible);
      observed = isVisible;
    }
    , {threshold});

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ ref ]);

  return [ isIntersecting, setIntersecting ];
};

