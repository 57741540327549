import "./card.css";

function TitleCard({
  title,
  children,
  description,
  className,
  spanForDescription,
}) {
  return (
    <div
      style={{
        background:
          "linear-gradient(0deg, #0E1330, #0E1330), linear-gradient(0deg, rgba(62, 109, 229, 0.3), rgba(62, 109, 229, 0.3))",
        border: "1px solid #3E6DE54D",
      }}
      className={"card w-full p-6 shadow-xl text-white " + className}
    >
      <div className="titleCard max-h-[240px] h-full w-full bg-transparent">
        {children}
      </div>

      <h3 className="text-[24px] mt-[20px] font-semibold leading-normal">
        {title}
      </h3>

      <p className="text-[#959595] mt-4 text-[20px]">
        {description} {spanForDescription && spanForDescription}
      </p>
    </div>
  );
}

export default TitleCard;
