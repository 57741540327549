import React from "react";

export const Button = ({ children, ...props }) => {
  return (
    <button
      {...props}
      className={`text-white bg-[#007DF2] py-[6px] md:py-[12px] w-full px-[25px] md:px-[56px] rounded-md hover:opacity-70 duration-150 ease-linear font-semibold ${props.className}`}
    >
      {children}
    </button>
  );
};
