import React from "react";
import { Header } from "../components/Header/Header";
import stars from "../assets/images/stars.png";
import { Demo } from "../components/Demo/Demo";
import { AnimatePresence, motion, wrap } from "framer-motion";
import truck from "../assets/images/truck.png";
import { Features } from "../components/Features/Features";
import { Footer } from "../components/Footer/Footer";
import { useRef } from "react";
import { useIsVisible } from "../hooks/useIsVisible";
import { Form } from "../components/Form/Form";
import { Button } from "../components/Button/Button";
import { Charts } from "../components/Charts/Charts";
import { Benefits } from "../components/Benefits/Benefits";
import { StarWars } from "../components/StarWars/StarWars";
import { Trucks } from "../components/Trucks/Trucks";
import truckMob from "../assets/images/truck-mobile.png";
import Videos from "../components/Videos/Videos";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import MyAntdModal from "../features/modalWindow/ModalWindow";

NiceModal.register("my-antd-modal", MyAntdModal);
const Home = () => {
  const antdModal = useModal("my-antd-modal");
  const showAntdModal = () => {
    antdModal.show();
  };

  const truckAnimation = {
    initial: { x: "2000px", scale: 0.9, opacity: 0 },
    animate: { x: "500px", scale: 1, opacity: 1 },
    exit: { x: "2000px", scale: 0.9, opacity: 0 },
    transition: { duration: 1.2, ease: "easeOut" },
  };
  const truckAnimationMobile = {
    initial: { x: "300px", scale: 0.9, opacity: 0 },
    animate: { x: "-170px", scale: 1, opacity: 1 },
    exit: { x: "100px", scale: 0.9, opacity: 0 },
    transition: { duration: 2, ease: "easeOut" },
  };

  const blockRef = useRef(null);

  const [isVisible] = useIsVisible(blockRef);

  return (
    <div>
      <div
        className="relative"
        style={{
          backgroundImage: `url(${stars})`,
          height: "100vh",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <Header />

        <div
          ref={blockRef}
          className="min-h-screen md:min-h-[1100px] absolute top-1/2 -translate-y-1/2 overflow-hidden w-full flex flex-col items-center justify-center"
        >
          <motion.div
            {...truckAnimationMobile}
            className="mobile-only relative display-flex"
            style={{
              backgroundImage: `url(${truckMob})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              width: "1000px",
              height: "680px",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              zIndex: -1,
            }}
          >
            <div className="absolute top-[350px] right-[135px] ml-[50px] flex gap-[20px] items-center">
              <div className="absolute bottom-[65px]">
                <h2 className="text-[#007DF2] text-center font-[Urbanist] text-[40px] not-italic font-bold leading-[normal] tracking-[0.25px]">
                  EasyFleet
                </h2>
                <h5 className="text-[#131313] mt-[5px] opacity-[0.6] text-center font-[Urbanist] text-[25px] not-italic font-medium leading-[normal] tracking-[0.12px] max-w-[256px]">
                  Streamline Your Fleet Maximize Savings
                </h5>
              </div>
              <a className="mobile-only block" href="#form">
                <Button className="!px-[7px] whitespace-nowrap">
                  Get a quote
                </Button>
              </a>
              <Button
                onClick={showAntdModal}
                className={
                  "!rounded-[8px] !px-[7px] !bg-[#17182A]  ![box-shadow:0px_4px_16px_0px_rgba(161,_161,_161,_0.50)] !text-[#FFF]"
                }
              >
                Request a demo
              </Button>
            </div>
          </motion.div>
          <AnimatePresence>
            {isVisible && (
              <motion.div className="desktop-only" {...truckAnimation}>
                <img
                  className="min-w-[3080px] h-full"
                  src={truck}
                  alt="truck"
                />
                <div className="absolute right-[48%] top-[320px] flex flex-col items-center justify-center">
                  <h1 className="text-[68px] text-[#007DF2] font-bold">
                    EasyFleet
                  </h1>
                  <p className="text-[41px] text-[#131313] font-bold text-center max-w-[715px] opacity-[0.6]">
                    Streamline Your Fleet <br /> Maximize Savings
                  </p>
                  <div class="flex items-center gap-x-5 mt-[15px]">
                    <a className="block" href="#form">
                      <Button
                        style={{
                          boxShadow: "0px 4px 16px 0px #A1A1A180",
                        }}
                        className="!px-[56px] whitespace-nowrap !w-full"
                      >
                        Get a quote
                      </Button>
                    </a>
                    <Button
                      onClick={showAntdModal}
                      style={{
                        boxShadow: "0px 4px 16px 0px #A1A1A180",
                      }}
                      className="bg-[#17182A] text-[#007DF2] !w-full py-[12px] px-[56px] rounded-md hover:opacity-70 duration-150 ease-linear font-semibold"
                    >
                      Request a demo
                    </Button>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <Demo />
      <Features />
      <Videos />
      <Charts />
      <Trucks />
      {/* <Partners /> */}
      {/* FIX: Add in the future */}
      <Benefits />
      <StarWars />
      <Form />
      <Footer />
    </div>
  );
};

export default Home;
