import React from "react";
import demoBg from "../../assets/images/demo-bg.jpg";
import VideoItem from "./Item";
import resgister from "../../assets/videos/easy_fleet_sign_up.mp4";
import introduction from "../../assets/videos/easy_fleet_introduction.mp4";

const Videos = () => {
  return (
    <div
      className="text-center mobile-container mt-[100px] !py-[30px] md:!py-[64px] pl-2 pr-2"
      style={{
        backgroundImage: `url(${demoBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <h2 className="text-[25px] md:text-[50px] font-bold text-center  mb-[20px]">
        Traning videos
      </h2>
      <div className="flex mobile-video justify-center mt-[20px] max-w-[1100px] m-auto gap-4 gap-y-[50px] md:gap-y-[120px]">
        <VideoItem
          link={introduction}
          title={
            <p className="text-[20px] md:text-[30px] font-bold text-left md:text-center mb-[10px] md:mb-6">
              Introduction Video of{" "}
              <span className="text-[#007DF2]">Easy Fleet</span>
            </p>
          }
        />
        <VideoItem
          link={resgister}
          title={
            <p className="text-[20px] md:text-[30px] font-bold text-left md:text-center mb-[10px] md:mb-6">
              How to Register for{" "}
              <span className="text-[#007DF2]">Easy Fleet </span>
            </p>
          }
        />
      </div>
    </div>
  );
};
export default Videos;
