import React, { useMemo, useRef } from "react";
import TitleCard from "../Cards/TitleCard";
import { useIsVisible } from "../../hooks/useIsVisible";
import { motion } from "framer-motion";
import firstImg from "../../assets/images/analytics/first-landing-analytics.png";
import secondImg from "../../assets/images/analytics/second-landing-analytics.png";
import thirdImg from "../../assets/images/analytics/third-landing-analytics.png";
import fourthImg from "../../assets/images/analytics/fourth-landing-analytics.png";
import fifthImg from "../../assets/images/analytics/fifth-landing-analytics.png";
import sixthImg from "../../assets/images/analytics/sixth-landing-analytics.png";

export const Charts = () => {
  const blockRef = useRef(null);
  const [isVisible] = useIsVisible(blockRef, true);

  const CHARTS = useMemo(
    () => [
      {
        img: firstImg,
        title: "Streamline Assignments",
        description:
          "Track all your fleet tasks with ease, increasing job completion rates by ",
        span: <span className="text-[#3E6DE5]">20%</span>,
      },
      {
        img: secondImg,
        title: "Track Miles Traveled",
        description:
          "Compare mileage across months or years to identify efficiency gains and cut fuel costs.",
      },
      {
        img: thirdImg,
        title: "Reduce Fault Frequency",
        description:
          "Quickly spot and address recurring issues to decrease breakdowns by up to ",
        span: <span className="text-[#3E6DE5]">15%</span>,
      },
      {
        img: fourthImg,
        isDowner: true,
        title: "Optimize Inventory Usage",
        description:
          "Stay updated on all vehicle conditions to maintain a fleet uptime of over ",
      },
      {
        img: fifthImg,
        title: "Monitor Vehicle Statuses",
        description:
          "Shows the relationship between different vehicle statuses.",
        span: <span className="text-[#3E6DE5]">90%</span>,
      },
      {
        img: sixthImg,
        title: "Mileage Insights",
        description:
          "Get weekly mileage reports to ensure your fleet is always running at peak performance.",
      },
    ],
    [],
  );

  const chartAnimation = {
    initial: { opacity: 0, scale: 0.7 },
    animate: { opacity: 1, scale: 1 },
    transition: { duration: 0.8, ease: "easeOut" },
  };

  return (
    <div className="mobile-container mt-[140px]">
      <div className="flex flex-col justify-center items-center">
        <h2 className="text-[30px] md:font-medium font-bold md:text-[50px] text-white text-center">
          Powerful Analytics at Your Fingertips
        </h2>
        <h6 className="text-[#959595] text-[20px] md:text-[26px] font-medium max-w-[872px] mt-[16px] mb-[24px] text-center">
          Unlock real-time insights on fleet performance with our easy-to-use
          platform. Gain actionable data on:
        </h6>
      </div>
      <ul
        className="max-w-[1240px] m-auto flex flex-wrap gap-5 pr-2 pl-2"
        ref={blockRef}
      >
        {CHARTS.map((item, idx) => (
          <li key={idx} className="w-full md:w-[calc(100%/3-15px)] mb-10">
            <TitleCard
              spanForDescription={item.span}
              title={item.title}
              description={item.description}
              className="h-full"
            >
              {isVisible && (
                <motion.img
                  {...chartAnimation}
                  alt="chart"
                  src={item.img}
                  className={`${
                    item.isDowner && "mt-[70px]"
                  } w-full max-h-[220px] object-fill h-full flex items-center justify-center`}
                />
              )}
            </TitleCard>
          </li>
        ))}
      </ul>
    </div>
  );
};
