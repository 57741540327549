import React, { useRef } from "react";
import { useIsVisible } from "../../hooks/useIsVisible";
import { AnimatePresence, motion } from "framer-motion";

export const FeatureCard = ({
  title,
  description,
  idx,
  className,
  suffix,
  classNameTitle,
  classNameDescription,
}) => {
  const cardRef = useRef(null);
  const [isVisible] = useIsVisible(cardRef, true);

  const cardAnimation = {
    initial: { opacity: 0, scale: 0.7 },
    animate: { opacity: 1, scale: 1 },
    transition: { duration: 0.8, ease: "easeOut", delay: idx * 0.1 },
  };

  const numberAnimation = {
    initial: { opacity: 0, scale: 0.7 },
    animate: { opacity: 1, scale: 1 },
    transition: { duration: 0.8, ease: "easeOut", delay: 0.2 + idx * 0.1 },
  };

  return (
    <div ref={cardRef}>
      {isVisible && (
        <motion.div
          {...cardAnimation}
          className={`bg-white w-[420px] min-h-[200px] md:min-h-[265px] p-[33px] overflow-hidden relative rounded-xl ${className}`}
        >
          <h3
            className={`text-[18px] text-[#131313] font-semibold ml-4 md:ml-0 max-w-[244px] md:max-w-[9999px] md:text-[24px] mb-[15px] ${classNameTitle}`}
          >
            {title} {suffix ? suffix : null}
          </h3>
          <p
            className={`text-[#959595] leading-[1] font-normal max-w-[244px] ml-4 md:ml-0 md:max-w-[356px] text-[14px] md:text-[20px] ${classNameDescription}`}
          >
            {description}
          </p>
          <AnimatePresence>
            {isVisible && (
              <motion.div
                {...numberAnimation}
                className="text-[40px] absolute bottom-[-10px] right-2 md:right-[-10px] rounded-t-full rounded-s-full w-[100px] h-[100px] bg-[#007DF2] flex justify-center items-center"
              >
                <p className="text-white font-semibold">{idx + 1}</p>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </div>
  );
};
