import React from "react";
import { Modal, Form, Input } from "antd";
import NiceModal, { useModal, antdModal } from "@ebay/nice-modal-react";
import { Button } from "../../components/Button/Button";
import styles from "./ModalWindow.module.css";
import { alertToast } from "../common/constants";
export default NiceModal.create(() => {
  const modal = useModal();

  const onFinish = (values) => {
    fetch("https://dev-app.easyfleet.ai/api/v1/common/consultation/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then(
        () => alertToast("The quote successfully sent", "success"),
        modal.hide(),
      )
      .catch((err) => {
        alertToast(err.message, "error");
      });
  };

  return (
    <Modal className={styles.modal} {...antdModal(modal)} footer={null}>
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input placeholder="Enter your name" />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phone_number"
          rules={[
            { required: true, message: "Please input your phone number!" },
          ]}
        >
          <Input placeholder="Enter your phone number" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please input your email!" },
            { type: "email", message: "Please enter a valid email!" },
          ]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>

        <Form.Item label="Company Name" name="company_name">
          <Input placeholder="Enter your company name" />
        </Form.Item>
        <Form.Item
          label="Message"
          rules={[{ required: true, message: "Please input your Message!" }]}
          name="message"
        >
          <Input placeholder="Enter your Message" />
        </Form.Item>

        <Form.Item>
          <Button
            style={{ boxShadow: "0px 4px 8px 0px rgba(0, 93, 172, 0.20)" }}
            className="bg-[#17182A]"
            type="primary"
            htmlType="submit"
          >
            Request a demo
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
});
