import React from 'react'

export const CustomInput = (props) => {
  return (
    <input 
        aria-label="input" 
        type="text" 
        {...props}
        className={`mb-6 bg-white border border-gray-100 shadow-md text-gray-900 text-sm rounded-md focus:ring-gray-200 focus:border-gray-200 block w-full p-2.5 ${props.className}`} 
    />
  )
}

export const CustomTextArea = (props)  => {
    return (
        <textarea 
            {...props}
            class={`block p-2.5 w-full h-[100px] resize-none bg-white border border-gray-100 shadow-md text-gray-900 text-sm rounded-md focus:ring-gray-200 focus:border-gray-200 ${props.className}`}
        ></textarea>
    )
}