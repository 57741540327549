import React, { useEffect, useRef } from "react";
import truck from "../../assets/images/moving-truck.png";
import lines from "../../assets/images/x-lines.png";
import { useIsVisible } from "../../hooks/useIsVisible";
import { useMediaQuery } from "../../hooks/useMediaQuery";

export const Benefits = () => {
  const truckRef = useRef(null);
  const [isVisible] = useIsVisible(truckRef, true, 1);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const SCROLL_STEP = isMobile ? 0.5 : 8;

  useEffect(() => {
    if (!isVisible) return;

    let lastScrollTop = window.pageYOffset;
    let prevScroll = 0;

    const handleScroll = () => {
      const scrollTopPosition = window.pageYOffset;

      if (lastScrollTop < scrollTopPosition) {
        if (prevScroll + SCROLL_STEP > 1740) return;
        truckRef.current.style.transform = `translateX(${
          prevScroll + SCROLL_STEP
        }px)`;
        prevScroll += SCROLL_STEP;
      } else {
        if (prevScroll - SCROLL_STEP < 0) return;
        truckRef.current.style.transform = `translateX(${
          prevScroll - SCROLL_STEP
        }px)`;
        prevScroll -= SCROLL_STEP;
      }

      lastScrollTop = scrollTopPosition;
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isVisible, SCROLL_STEP]);

  return (
    <div
      style={{
        backgroundImage: `url(${lines})`,
        height: "600px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      className="pt-[30px] overflow-hidden md:pt-[100px] relative h-[500px]"
    >
      <h2 className="text-white text-center px-4 text-[30px] md:text-[50px] mb-[150px] md:mb-[130px] font-bold max-w-[370px] md:max-w-[920px] m-auto">
        Harness powerful and beneficial capabilities for managing your fleet
        with Easy Fleet.
      </h2>
      <div className="max-w-[1740px] relative mx-auto">
        <img
          ref={truckRef}
          src={truck}
          alt="truck"
          className={`duration-75 ease-linear absolute left-0 top-[-90px] md:top-[-125px] ${
            isMobile ? "w-[250px] h-auto" : ""
          }`}
        />
      </div>
      <div
        style={{
          background:
            "linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 102.81%)",
        }}
        className="w-full h-[14px]"
      />
      <div className="bg-white h-[163px] mt-4">
        <ul className="flex mobile-container items-center justify-between max-w-[920px] m-auto">
          <li className="flex flex-col items-center gap-y-[24px] max-w-[200px] text-center -translate-y-[27px]">
            <div
              style={{
                border: "2px solid #007DF2",
              }}
              className="w-[54px] h-[54px] rounded-full bg-white border border-[#007DF2] relative"
            >
              <div className="absolute w-[21px] h-[21px] bg-[#007DF2] top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 rounded-full" />
            </div>
            <p className="text-[14px] md:text-[20px] font-medium max-w-[78px] md:max-w-full">
              Register and get subscription
            </p>
          </li>
          <li className="flex flex-col items-center gap-y-[24px] max-w-[200px] text-center -translate-y-[27px]">
            <div
              style={{
                border: "2px solid #007DF2",
              }}
              className="w-[54px] h-[54px] rounded-full bg-white border border-[#007DF2] relative"
            >
              <div className="absolute w-[21px] h-[21px] bg-[#007DF2] top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 rounded-full" />
            </div>
            <p className="text-[14px] md:text-[20px] font-medium max-w-[123px] md:max-w-full">
              Streamline Operations with Easy Fleet
            </p>
          </li>
          <li className="flex flex-col items-center gap-y-[24px] max-w-[200px] text-center -translate-y-[27px]">
            <div
              style={{
                border: "2px solid #007DF2",
              }}
              className="w-[54px] h-[54px] rounded-full bg-white border border-[#007DF2] relative"
            >
              <div className="absolute w-[21px] h-[21px] bg-[#007DF2] top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 rounded-full" />
            </div>
            <p
              id="benefits"
              className="text-[14px] md:text-[20px] font-medium md:max-w-full max-w-[117px]"
            >
              Experience Seamless Control and Growth
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};
