import React from "react";
import logo from "../../assets/icons/logo.svg";
import { Button } from "../Button/Button";

export const Header = () => {
  return (
    <header className="pt-4 z-50 absolute top-0 w-full">
      <nav className="border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
        <div className="flex flex-wrap justify-between items-start mx-auto max-w-screen-xl">
          <a href="/" className="flex items-center">
            <img
              src={logo}
              className="mr-3 w-[160px] md:w-[230px] h-[70px] md:h-[60px]"
              alt="easy fleet Logo"
            />
          </a>
          <a
            className="block mobile-only text-inherit bg-inherit lg:inline-block mt-4 lg:mt-0 "
            href="https://app.easyfleet.ai/login"
            target="_blank"
            rel="noreferrer"
          >
            <Button className="!rounded-[8px] !bg-[#FFF] ![box-shadow:0px_4px_16px_0px_rgba(161,_161,_161,_0.50)] !text-[#007DF2]">
              Login
            </Button>
          </a>
          {/* FIX add in future */}
          {/* <div className="lg:hidden">
            <button
              onClick={toggleMobileMenu}
              className="block text-white focus:outline-none"
              aria-label="Toggle mobile menu"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                {isMobileMenuOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          </div> */}
          {/* <div
            className={`lg:flex ${
              isMobileMenuOpen ? "block" : "hidden"
            } mt-4 lg:mt-0`}
          >
            <ul className="flex flex-col lg:flex-row lg:space-x-8">
              <li>
                <a
                  href="#about"
                  className="block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                  aria-current="page"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="#features"
                  className="block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                  aria-current="page"
                >
                  Features
                </a>
              </li>
              <li>
                <a
                  href="#benefits"
                  className="block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                  aria-current="page"
                >
                  Benefits
                </a>
              </li>
            </ul>
          </div> */}
          <div className="desktop-only flex gap-3">
            <a className="block lg:inline-block mt-4 lg:mt-0" href="#form">
              <Button>Get a quote</Button>
            </a>
            <a
              className="block text-inherit bg-inherit lg:inline-block mt-4 lg:mt-0 "
              href="https://app.easyfleet.ai/login"
              target="_blank"
              rel="noreferrer"
            >
              <Button className="!rounded-[8px] !bg-[#FFF] ![box-shadow:0px_4px_16px_0px_rgba(161,_161,_161,_0.50)] !text-[#007DF2]">
                Login
              </Button>
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
};
