import React, { useRef } from 'react';
import leftLines from '../../assets/images/left-lines.png';
import rightLines from '../../assets/images/right-lines.png';
import scale from '../../assets/images/scale.svg';
import { useIsVisible } from '../../hooks/useIsVisible';
import './star.css';

export const StarWars = () => {
  const scaleRef = useRef(null);

  const [isVisible] = useIsVisible(scaleRef, true);

  return (
    <div
      className="relative mobile-container h-[70dvh] md:h-[130vh] overflow-hidden"
      ref={scaleRef}
    >
      <img
        className="absolute left-0 top-[-200px] z-[-1]"
        src={leftLines}
        alt="lines"
      />
      <img
        className="absolute right-0 top-[-200px] z-[-1]"
        src={rightLines}
        alt="lines"
      />

      <div className="star mx-auto relative top-1/2 -translate-y-1/2">
        {isVisible && (
          <img
            src={scale}
            style={{ objectFit: 'cover' }}
            alt="scale"
            className=""
          />
        )}
      </div>
    </div>
  );
};
